import React from 'react';

function HomeScreen() {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '3rem',
    color: '#333',
  };

  const subHeadingStyle = {
    fontSize: '1.5rem',
    color: '#666',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Coming Soon...</h1>
      <br/>
      <h4 style={subHeadingStyle}>(In Development)</h4>
    </div>
  );
}

export default HomeScreen;
