import React, { useState } from 'react';

function SupportForm() {
  const [app, setApp] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailAddress = 'support@ddobso.com';
    const subject = encodeURIComponent(`Support Request - ${app}`);
    const body = encodeURIComponent(
      `App: ${app}\nUser Email: ${userEmail}\n\nMessage:\n${message}`
    );
    window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  };

  return (
    <div style={styles.container}>
      <h2>Contact Support</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <label>
          Select Your App:
          <select
            value={app}
            onChange={(e) => setApp(e.target.value)}
            required
            style={styles.input}
          >
            <option value="">--Please choose an app--</option>
            <option value="Aquatic Space">Aquatic Space</option>
            {/* Add more apps as needed */}
          </select>
        </label>

        <label>
          Your Email:
          <input
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            placeholder="your.email@example.com"
            style={styles.input}
          />
        </label>

        <label>
          Your Message:
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            placeholder="Describe your issue or feedback here..."
            rows={5}
            style={styles.textarea}
          />
        </label>

        <button type="submit" style={styles.button}>
          Send Email
        </button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    paddindTop: '50px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f2f2f2',
    padding: '50px',
    margin: '0 auto',
    maxWidth: '600px',
  },
  form: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
  },
  input: {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    marginBottom: '15px',
    boxSizing: 'border-box',
  },
  textarea: {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    marginBottom: '15px',
    boxSizing: 'border-box',
  },
  button: {
    padding: '10px 15px',
    cursor: 'pointer',
  },
};

export default SupportForm;
