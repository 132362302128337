import React from 'react';
import { Link } from 'react-router-dom';

const AquaticSpacePrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', maxWidth: '800px', margin: 'auto' }}>
      <h1 style={{ textAlign: 'center' }}>Privacy Policy for Aquatic Space</h1>
      <p style={{ fontStyle: 'italic', textAlign: 'center' }}>Last Updated: [08/11/2024]</p>

      <p>Welcome to Aquatic Space! We value your privacy and are committed to protecting your personal information. This Privacy Policy explains our policies regarding the collection, use, and protection of any data you provide while using our app.</p>

      <p>By accessing or using Aquatic Space, you agree to this Privacy Policy. If you disagree with any part of this Privacy Policy, please do not use the app or our services.</p>

      <h2>1. Information We Collect</h2>
      <p>Aquatic Space does not collect any personal information from its users. We do not require you to submit any personal data, nor do we collect any usage data, IP addresses, or device information automatically. Our app is designed to respect your privacy, and we have deliberately chosen to avoid collecting unnecessary information.</p>

      <h2>2. How We Use Your Information</h2>
      <p>Since we do not collect any personal information, we do not use your data in any form. Our app is built to function without the need for data collection or user profiling.</p>

      <h2>3. Sharing Your Information</h2>
      <p>We do not share, sell, or rent any information, as we do not collect any personal data from you. There is no information that we share with third parties.</p>

      <h2>4. Security of Your Information</h2>
      <p>Aquatic Space takes privacy seriously. Since we do not collect any personal information, there are no specific data security concerns related to user data. Nevertheless, we have implemented measures to ensure that the app remains secure from external threats.</p>

      <h2>5. Your Rights</h2>
      <p>As Aquatic Space does not collect any personal information, there are no specific rights related to data access, modification, or deletion. You are free to use the app without any requirement to provide personal information.</p>

      <h2>6. Children's Privacy</h2>
      <p>Aquatic Space is not intended for children under the age of 13. We do not knowingly collect any information from children. If we discover that information from a child under 13 has been collected unintentionally, we will promptly delete that information.</p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted directly within this policy section. We encourage you to periodically review this policy to stay informed about our practices. Your continued use of Aquatic Space signifies your acceptance of any updates.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions or concerns regarding this Privacy Policy, please contact us through the app’s available support options.</p>
      <Link to="/support">Support</Link>
      <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '20px' }}>Thank you for using Aquatic Space and trusting us with your privacy.</p>
    </div>
  );
};

export default AquaticSpacePrivacyPolicy;
