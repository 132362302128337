import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SupportForm from './components/SupportForm';
import HomeScreen from './components/HomeScreen';
import AquaticSpacePrivacyPolicy from './components/AquaticSpacePrivacyPolicy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/support" element={<SupportForm />} />
        <Route path="/aquatic-space-privacy-policy" element={<AquaticSpacePrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
